<template>
    <div>
        <div class="absolute flex items-center -top-14 right-0">
            <el-checkbox v-if="!isGlobalClientSelected" v-model="bolagerietFilter" class="pr-4" @change="getAll">Bolageriet filter</el-checkbox>
            <el-button type="primary" @click="removeFromMonitorList" :loading="$waiting.is('removing_from_monitor_list')" :disabled="selectedIds.length === 0">Ta bort</el-button>
        </div>
        <el-table :data="bevakasData" class="w-full" @selection-change="changeRowsSelection">
            <el-table-column type="selection" width="45" />
            <el-table-column label="FEN" prop="clientNumber" sortable width="80" />
            <el-table-column label="Klient" prop="clientName" sortable />
            <el-table-column label="Kund" prop="customerName" sortable />
            <el-table-column label="Fak nr" prop="invoiceNumber" sortable />
            <el-table-column label="OCR" prop="ocrNumber" sortable />
            <el-table-column label="Kvar att batala" prop="paymentRest" align="right">
                <template slot-scope="scope">{{ scope.row.paymentRest | swedishNumberFormat }} {{ scope.row.currency }}</template>
            </el-table-column>
            <el-table-column label="Bruttobelopp" prop="grossAmount" align="right">
                <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} {{ scope.row.currency }}</template>
            </el-table-column>
            <el-table-column label="Datum" prop="date" width="120" align="center">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.date) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Förtallodatum" prop="dueDate" width="120" align="center">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.dueDate) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Påminelse 1/2" sortable />
            <el-table-column label="E-post/Brev" prop="sendStatus" sortable />
            <el-table-column width="90" align="right">
                <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.hasComment" class="item" effect="dark" content="Kommentarer" placement="left">
                        <el-button @click="openCommentsModal(scope.row.id)" type="primary" class="p-1 mr-1">
                            <i class="far fa-comment-captions" />
                        </el-button>
                    </el-tooltip>
                    <router-link :to="'invoices/' + scope.row.id">
                        <el-button type="primary" class="p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                            </svg>
                        </el-button>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../att_atgarda.api";

export default {
    data() {
        return {
            bevakasData: [],
            bolagerietFilter: false,
            selectedIds: [],
        };
    },

    watch: {
        isGlobalClientSelected(newValue) {
            if (!newValue) {
                this.getAll();
            }
        },
    },

    created() {
        this.getAll();
        document.title = "Att åtgärda - Bevakas";
    },

    methods: {
        async getAll() {
            this.$waiting.start("loading");
            try {
                this.bevakasData = await Api.getBevakas(false, this.$store.state.topBar.selectedClient.value);
            } finally {
                this.$waiting.end("loading");
            }
        },

        changeRowsSelection(rows) {
            this.selectedIds = rows.map(item => item.id);
        },

        async removeFromMonitorList() {
            this.$waiting.start("removing_from_monitor_list");
            try {
                await Api.removeFromMonitorList(this.selectedIds);
                this.$notify.success({ title: "Utfört" });
                this.getAll();
            } finally {
                this.$waiting.end("removing_from_monitor_list");
            }
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        openCommentsModal(id) {
            this.$emit("showCommentsModal", id);
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
